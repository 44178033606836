.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #3e79f7;
}

.presc-select-cont {
  position: relative;
}

.dropdown-content {
  position: initial !important;
}

/* .presc-select-cont .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 93%;
  width: 90%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: rgba(0, 0, 0, 0.5);
}

.presc-select-cont .selected-active .overlay {
  opacity: 1;
}

.presc-select-cont .icon {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.presc-select-cont .fi-rs-search:hover {
  color: #eee;
} */

.phone-ant-input {
  display: flex;
  align-items: center;
}

.PhoneInputCountry img {
  height: 13px !important;
}

.PhoneInputCountry select {
  width: 18px;
  border: none;
}

.PhoneInputCountry {
  display: flex;
  flex-direction: row-reverse;
}


.phone-ant-input .PhoneInputInput {
  flex: 1;
  box-sizing: border-box;
  margin: 0px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0px;
  padding: 8.5px 11px;
  color: rgb(69, 85, 96);
  font-size: 14px;
  line-height: 1.5;
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid rgb(230, 235, 241);
  border-radius: 0.625rem;
  transition: all 0.3s ease 0s;
}

.phone-ant-input .PhoneInputInput:disabled {
  background-color: #f5f5f5 !important;
}

.phone-ant-input-customer .PhoneInputInput {
  flex: 1;
  box-sizing: border-box;
  margin: 0px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0px;
  padding: 8.5px 11px;
  color: rgb(69, 85, 96);
  font-size: 14px;
  line-height: 1.5;
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid rgb(230, 235, 241);
  border-radius: 0.625rem;
  transition: all 0.3s ease 0s;
}

.phone-ant-input-customer .PhoneInputInput:active {
  color: #03b2cb
}

.phone-ant-input-customer .PhoneInputInput::placeholder {
  color: #b7c4cf;
}

.product-ellipsis {
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.form-check-input:checked:before {

  background-color: rgb(67, 178, 126) !important;

}


/* @media (min-width: 768px) {
  .edit-button-prescription {
    transform: translate(640%);
    background-color: #3e79f7;
    color: white;
    width: 100px;
  }
} */

@media (max-width: 576px) {
  .edit-button-prescription {
    transform: translate(85%);

    background-color: #3e79f7;
    color: white;
  }
}

@media (max-width: 576px) {
  .phone-update {
    display: flex;
    flex-direction: column;


  }

  .phone-update Input {
    width: 100%;
  }

  .phone-update-button {
    width: 100%;
    margin-bottom: 20px;
  }

  .password-update-button {
    width: 65%;
    margin-bottom: 10px;
  }
}



@media(max-width:1600px) {
  .phone-update-button {
    width: 13vw;
  }

  .password-update-button {
    width: 13vw;
  }

  .update-input {
    width: 19vw;
  }
}

@media(min-width:991px) {
  .phone-update-button {
    width: 13vw;
  }

  .password-update-button {
    width: 13vw;
  }

  .update-input {
    width: 34.5vw;
  }
}

@media(max-width:990px) {
  .phone-update-button {
    width: 20vw;
  }

  .password-update-button {
    width: 20vw;
  }

  .update-input {
    width: 58%;
  }
}

@media(max-width:825px) {
  .phone-update-button {
    width: 24vw;
  }

  .password-update-button {
    width: 24vw;
  }

  .update-input {
    width: 55%;
  }
}

@media(max-width:780px) {
  .phone-update-button {
    width: 24vw;
  }

  .password-update-button {
    width: 24vw;
  }

  .update-input {
    width: 58%;
  }
}

@media(max-width:567px) {
  .phone-update-button {
    width: 48vw;
  }

  .password-update-button {
    width: 48vw;
  }

  .update-input {
    width: 100%;
  }
}

@media(max-width:300px) {
  .phone-update-button {
    width: 100%;
  }

  .password-update-button {
    width: 100%;
  }

  .update-input {
    width: 100%;
  }
}

/* .order-cancel-button {
  background-color: #ff0000;
  color: white;
  width: 10vw;
  margin-top: 120px;
}

.order-update-button {
  background-color: #3e79f7;
  color: white;
  width: 10vw;
  margin-top: 120px;
} */

@media(max-width:567px) {
  .cancel-update {
    flex-direction: column;
  }
}

@media(max-width:567px) {
  .shipping-address-create {
    flex-direction: column;
  }

  .order-update-button {
    width: 25vw;
    margin-top: 6px;
  }

  .order-cancel-button {
    width: 25vw;

  }

}

/* input[type='checkbox']:checked {
  background-color: #43b27e;
}

input[type='checkbox']:checked:after {
  content: '\2713';
  color: white;
}

input[type='checkbox'] {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  width: 20px !important;
  height: 20px !important;
  appearance: none;
  border-radius: 10%;
  box-shadow: none;
  font-size: 1em;
} */

/* checkbox style */


.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: rgb(255, 107, 114);
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;

}

.ant-upload-list-picture-card-container {
  height: 110px !important;
  width: 110px !important;
}


@media(max-width:768px) {}

.total-sales-section {
  display: flex;
  align-items: center;

}

@media(max-width: 576px) {
  .total-sales-section {
    flex-direction: column;
  }
}

.accounts-flex {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1vw;
  flex-wrap: wrap;
}

@media(max-width: 576px) {
  .accounts-flex {
    flex-direction: column;
  }
}

.accounts-card {
  width: 24%;
}

@media(max-width: 576px) {
  .accounts-card {
    width: 100%;
  }
}

.reports-card-section {
  height: 67px;
}

@media(max-width: 576px) {
  .reports-card-section {
    height: auto;
  }
}

.total-list {
  width: 21em;
}

/* .refund-status{
  display: flex;
  justify-content: center;
  align-items: center;
} */

.update-inputs {
  width: 100%
}

.left-label {
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  color: rgb(26, 51, 83);
}

.right-symbol {
  margin-left: 3px;
  font-family: Simsun;
  font-size: 14px;
  color: rgb(255, 107, 114);
}

.amount-span {
  font-weight: 500;
  color: rgb(26, 51, 83);
  font-size: 14px;
  width: 10vw;
  display: inline-block;
}

.form-check-input:checked:before {
  background-color: rgb(67, 178, 126) !important;
}

.form-check-input:checked:before {
  background-color: rgb(67, 178, 126) !important;
}

/* .ant-menu-item-group-title {
  padding: 12px 24px;
  color: red;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 15px;
  opacity: 0 !important;
} */

.invoice-modal .ant-modal-body {
  padding: 0 !important;
  font-size: 14px;
  line-height: 1.5;
  overflow-wrap: break-word;

}

.invoice-modal {
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.invoice-table .ant-table-tbody>tr>td,
.invoice-table .ant-table-tbody>tr>th {
  border: none !important;
}

.invoice-table .ant-table-thead>tr>th {
  border-bottom: none !important;
}

.invoice-table .ant-modal-header {
  padding: 16px 16px !important;
}

.invoice-table {
  padding-left: 8px;
  padding-right: 8px;
}

.product-excel-modal {
  position: 'relative' !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.composition-modal {
  position: 'relative' !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.selected-card {
  border: 2px solid #3bb77e !important;
}

.break-word {
  word-break: break-all;
}

.pop-confirm-input .ant-popover-inner {
  width: 400px !important;
}

.pop-confirm-input .ant-popover-content {
  width: 400px !important;
}

.tags-column {
  width: 200px !important;
}

.ant-table-tbody>tr>td {
  border-bottom: 1px solid rgb(230, 235, 241);
  transition: background 0.3s;
  max-width: 250px !important;
}

.register-phone-input .react-tel-input .form-control {
  width: 100% !important;
  border-radius: 0.625rem !important;
  border: 1px solid rgb(230, 235, 241) !important;
  font-size: 14px !important;
  padding: 8.5px 11px 8.5px 58px !important;
}

.register-phone-input .react-tel-input .form-control:hover {
  border: 1px solid rgb(62, 121, 247) !important;
}

@media (max-width:767px) {
  .register-phone-input .react-tel-input .form-control {
    font-size: 12px !important;
    padding: 7px 11px 7px 58px !important;
  }
}

.col-flex {
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  /* align-items: center; */
  /* justify-content: center; */
}

.row-flex {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  /* justify-content: center; */
}

.ant-input[disabled] {
  color: black !important;
  background-color: #f5f5f5 !important;
  opacity: 1 !important;
}

/* ----if this is used tab list dont remains static and scrolls below header ----  */
/* .static-bar {
  padding: 15px 25px 0px 25px !important;
  position: sticky;
  top: 70px;
  z-index: 100;
}

.container .ant-tabs {
  margin-left: -34px !important;
  margin-right: -34px !important;
}

.static-tabs-list {
  margin-top: 74px;
}

.static-tabs-list .ant-tabs-nav-list {
  padding-left: 34px !important;
  padding-right: 34px !important;
}

.static-tabs-list .ant-tabs-nav {
  background-color: white;
  position: sticky !important;
  z-index: 99;
  top: 0px !important;
}

.static-tabs-list .ant-tabs-content-holder {
  margin-left: 34px !important;
  margin-right: 34px !important;
} */

/* ---------- tab list design which scrolls ends ---------- */


/* ----if this is used tab list also remains static but inbuilt horizontal scroll of tablist breaks ----  */
.static-bar {
  padding: 15px 25px 10px 25px !important;
  position: sticky;
  top: 70px;
  z-index: 100;
}

.container .ant-tabs {
  margin-left: -34px !important;
  margin-right: -34px !important;
}


.static-tabs-list {
  margin-top: 70px !important;
}

.static-tabs-list .ant-tabs-nav-list {
  padding-left: 34px !important;
  padding-right: 34px !important;

}

.static-tabs-list .ant-tabs-nav-wrap {
  position: fixed !important;
  top: 142.8px !important;
  z-index: 101 !important;
  background-color: white;
  width: 100%;
  border-bottom: 1px solid #e6ebf1 !important;
}

.static-tabs-list .ant-tabs-nav-wrap, .ant-tabs-nav-operations {
  z-index: 102 !important;
}

.static-tabs-list .ant-tabs-content-holder {
  margin-top: 30px !important;
  margin-left: 34px !important;
  margin-right: 34px !important;
}

/* ---------- tab list design without horizontal scroll ends ---------- */

.filtersSection {
  /* changing the padding of this style will affect table header row position */
  position: sticky;
  top: 70px;
  padding-top: 5px;
  z-index: 999;
  background-color: white;
  padding-bottom: 16px;
}

.navbar-select {
  width: 100px;
  height: 35px;
}

.navbar-select .ant-select-selector {
  border: none !important;
  background: #f01e29 !important;
  color: #ffffff !important;
  box-shadow: none !important;
  font-size: 20px !important;
  border-radius: 5px !important;
  /* font-family: Poppins !important; */
}

.navbar-select .ant-select-selector .ant-select-selection-item {
  font-size: 14px !important;
  font-weight: 700 !important;
  margin-bottom: 4px;
  color: #ffffff !important;
  font-family: Georama !important;
}

.navbar-select .ant-select-selection-placeholder {
  color: #ffffff !important;
}

.navbar-select .ant-select {
  color: #ffffff !important;
}

.navbar-select .ant-select-arrow {
  color: #ffffff !important;
}

.navbar-select-dropdown .ant-select-item-option {
  font-size: 16px !important;
  font-weight: 700 !important;
}

.navbar-select .ant-select-item-option-content {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: white !important;
  background: #f01e29 !important;
}

.language-select .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: red !important;
  color: white !important;
  font-weight: 700;
}

.navbar-select-placeholder-icon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1400px) {
  .navbar-select {
    width: 100px;
    height: 28px;
  }

  .navbar-select .ant-select-selector .ant-select-selection-item {
    font-size: 13px !important;
    margin-bottom: 4px;
  }

  .navbar-select-dropdown .ant-select-item-option {
    font-size: 13px !important;
  }
}

@media (max-width: 1024px) {
  .navbar-select {
    width: 100%;
    height: 26px;
  }

  .navbar-select .ant-select-selector {
    font-size: 12px !important;
  }

  .navbar-select .ant-select-selector .ant-select-selection-item {
    font-size: 12px !important;
    margin-top: 2px !important;
  }

  .navbar-select-dropdown .ant-select-item-option {
    font-size: 12px !important;
  }

  .navbar-select .ant-select-selector .ant-select-selection-item {
    font-size: 12px !important;
  }
}

/* 
.ant-tooltip-inner{
  display: none !important;
}

.ant-table-thead th.ant-table-column-has-sorters{
  justify-content: space-between !important;;
} */

.table-column-hover {
  /* padding: 10px 15px 10px 10px; */
  border-radius: 5px;
}

.table-column-hover:hover {
  /* background-color: transparent; */
  /* background-color: rgba(230, 235, 241, 0.3); */
  cursor: pointer;
}

.ant-table-thead .ant-table-cell:hover {
  background-color: rgb(245, 245, 245) !important;
  cursor: pointer;
}

.ant-table tfoot>tr>th, .ant-table-thead>tr>th {
  position: relative;
  /* padding: 0px !important; */
  overflow-wrap: break-word;
}


.image-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.image-container img {
  width: 50% !important;
  border-radius: 10px;
  /* margin-left: auto !important;
  margin-right: auto !important; */
}

/* .active-column {
  background-color: rgb(245, 245, 245); 
  font-weight: bold;         
}

th.active-column {
  background-color: rgb(245, 245, 245); 
} */

.sourcelink-main {
  display: flex;
  flex-direction: column;
}

.itemsList p {
  color: #1A3353;
}

.bold {
  font-weight: 600 !important;
}

.pointer {
  cursor: pointer !important;
}

.mr-info {
  margin-right: 10px;
  font-size: 16px;
}

.title-link {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #3e79f7;
  cursor: pointer;
}

.ellipsis-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}